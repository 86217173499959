<template>
    <div class="notification-cc">
        <div class="title-actions-container">
            <h2 class="notification-title">Add Notification Setting <span class="required-info">*All fields required</span></h2>
            <div class="actions-container">
                <k-button class="cc-save-btn" @click="onSaveCCRows" :disabled="saveCCSettingsBtnDisabled"><i class="fa-sharp fa-light fa-floppy-disk"></i> Save</k-button>
                <k-button class="cc-cancel-btn" @click="onClearCCRows"><i class="fa-light fa-rotate-left"></i> Cancel</k-button>
            </div>
        </div>
        <div class="notification-rows-container" v-if="!loading && dataLoaded">
            <div class="notification-row" v-for="row in notificationCCRows" :key="row.id">
                <div class="notification-row-item">
                    <label>Notifications To</label>
                    <k-multiselect :data-items="row.filteredUsers" 
                                v-model="row.toUsers"
                                :text-field="'name'"
                                :placeholder="'Select User(s)'"
                                :data-item-key="'id'"
                                :filterable="true"
                                @filterchange="onUserFilterChange($event, row.id)"
                                @change="onToUsersChange($event, row.id)"></k-multiselect>
                </div>
                <div class="notification-row-item">
                    <label>Notification(s)</label>
                    <k-multiselect :data-items="row.filteredNotis"
                                   v-model="row.notis"
                                   :text-field="'displayName'"
                                   :placeholder="'Select Notification(s)'"
                                   :data-item-key="'id'"
                                   :filterable="true"
                                   @filterchange="onNotiFilterChange($event, row.id)"
                                   @change="onNotificationsChange($event, row.id)"></k-multiselect>
                </div>
                <div class="notification-row-item">
                    <label>CC To <span>Select or enter custom email</span></label>
                    <k-multiselect :data-items="row.filteredCCUsers" 
                                v-model="row.ccUsers"
                                :text-field="'email'"
                                :placeholder="'Select User(s)'"
                                :data-item-key="'id'"
                                :allow-custom="true"
                                :filterable="true"
                                @filterchange="onCCFilterChange($event, row.id)"
                                @change="onCCUsersChange"></k-multiselect>
                </div>
            </div>
        </div>

        <div class="component__loader-container" v-if="loading">
            <k-loader size="large" type="infinite-spinner"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default__notification-container">
            <k-fade :appear="successNotification">
                <k-notification v-if="successNotification"
                                :type="{ style: 'success', icon: true }"
                                :closable="true"
                                @close="onCloseNotification('successNotification')">
                    <span>{{ notificationMsg }}</span>
                </k-notification>
            </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Loader } from "@progress/kendo-vue-indicators";
    import { MultiSelect } from '@progress/kendo-vue-dropdowns';
    import { Input } from '@progress/kendo-vue-inputs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";

    import { filterBy } from '@progress/kendo-data-query';

    import apiService from '../api/api.service.js';
    import auth from '../authConfig.js';

    import cloneDeep from 'lodash.clonedeep';

    export default {
        name: 'NotificationCC',
        props: ['addedNotifications', 'azureUsers', 'notificationsAdded'],
        emits: ['notisloaded', 'notisupdated'],
        data() {
            return {
                allTemplatesModel: { displayName: 'All Notifications', id: Math.random().toString() },
                allUsersModel: { name: 'All Users', email: null, id: Math.random().toString() },
                azureId: null,
                ccSettingModel: {
                    guid: null,
                    azure_id: null,
                    email_address: null,
                    cc_address: null,
                    template_id: null,
                    loan_number: null,
                    type: 'To'
                },
                ccUsers: [],
                dataLoaded: false,
                errorMsg: '',
                errorTitle: '',
                loading: true,
                localAzureUsers: [],
                notificationCCRowModel: { id: Math.random().toString(), toUsers: [], notis: [], ccUsers: [] },
                notificationCCRows: [
                    { id: Math.random().toString(), toUsers: [], notis: [], ccUsers: [], filteredUsers: [], filteredNotis: [], filteredCCUsers: [] }
                ],
                notificationMsg: '',
                notificationTemplates: [],
                showErrorDialog: false,
                successNotification: false
            }
        },
        computed: {
            saveCCSettingsBtnDisabled() {
                return this.notificationCCRows[0].toUsers.length === 0 || this.notificationCCRows[0].notis.length === 0 || this.notificationCCRows[0].ccUsers.length === 0;
            }
        },
        watch: {
            notificationsAdded(val) {
                if (val) {
                    this.addedNotifications.forEach(noti => {
                        if (!this.notificationCCRows[0].notis.some(n => n.id === noti.id)) {
                            this.notificationCCRows[0].notis.push(noti);
                        }
                    });

                    if (this.notificationCCRows[0].notis.length > 1) {
                        this.notificationCCRows[0].notis = this.notificationCCRows[0].notis.filter(noti => noti.displayName !== 'All Notifications');
                    }
                }
            }
        },
        async mounted() {
            const msalAppInstance = await auth.init();

            msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {
                if (!tokenRes) {
                    if (!auth.user()) {
                        auth.login();
                    }
                }

                this.azureId = localStorage.getItem('azure_id');

                if (!this.azureId) return;

                if (this.azureUsers?.length > 0) {
                    this.localAzureUsers = cloneDeep(this.azureUsers);
                    this.assignUsers()
                } else {
                    await this.getUsersByOrgId();
                }

                await this.getNotificationTemplates();
                await this.getUsers();

                this.dataLoaded = true;
                this.loading = false;
            });
        },
        methods: {
            assignNotificationTemplates(templatesData) {
                templatesData.forEach(template => template.displayName = `${template.title} - ${template.category}`);
                templatesData.sort((a, b) => a.displayName.localeCompare(b.displayName));

                this.$emit('notisloaded', cloneDeep(templatesData));
                this.notificationTemplates = templatesData;

                if (this.notificationTemplates.length === 1) {
                    this.notificationCCRows[0].notis = [this.notificationTemplates[0]];
                } else {
                    this.notificationTemplates.unshift(this.allTemplatesModel);
                }

                this.notificationCCRows[0].filteredNotis = cloneDeep(this.notificationTemplates);
            },
            assignCCUsers(ccUsersData) {
                ccUsersData.sort((a, b) => a.displayName.localeCompare(b.displayName));

                this.ccUsers = ccUsersData.map(user => {
                    return {
                        name: user.displayName,
                        email: user.email,
                        id: user.id
                    }
                });

                this.notificationCCRows[0].filteredCCUsers = cloneDeep(this.ccUsers);
            },
            assignUsers() {
                let currentUser = this.localAzureUsers.find(user => user.id === this.azureId);

                if (currentUser) {
                    this.notificationCCRows[0].toUsers = [currentUser];
                }

                if (this.localAzureUsers.length > 1) {
                    this.localAzureUsers.unshift(this.allUsersModel);
                }

                this.notificationCCRows[0].filteredUsers = cloneDeep(this.localAzureUsers);
            },
            async getNotificationTemplates() {
                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetNotificationTemplates(tokenResponse.accessToken)
                    .then(res => {
                        if (res.data?.data) {
                            this.assignNotificationTemplates(res.data.data);
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch Notification Templates';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            async getUsers() {
                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetUsers(tokenResponse.accessToken)
                    .then(res => {
                        if (res.data?.data) {
                            this.assignCCUsers(res.data.data);
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch Users';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            async getUsersByOrgId() {
                const tokenResponse = await auth.acquireTokenResponse(); 

                //testing azure ids...
                //this.azureId = '37a013ac-f5f0-46c1-8734-5e855474a4d4' //Jared Peterson
                //this.azureId = '11127588-9290-464e-8d06-3e14fec2b0ae' //Marty Schaefer
                //this.azureId = 'd588e053-971a-4e87-8909-58dc9e36880b' //Doug Welch

                return apiService.GetUsersByOrgIdByRole(this.azureId, tokenResponse.accessToken)  
                    .then(res => {
                        if (res.data?.data) {
                            this.localAzureUsers = res.data.data.map(user => {
                                return {
                                    name: user.displayName,
                                    email: user.email,
                                    id: user.id
                                }
                            });

                            this.assignUsers();
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch Users';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            onCCFilterChange(evt, rowId) {
                let row = this.notificationCCRows.find(row => row.id === rowId);

                if (row) {
                    row.filteredCCUsers = filterBy(cloneDeep(this.ccUsers), evt.filter);
                }
            },
            onCCUsersChange(evt) {
            },
            onClearCCRows() {
                this.notificationCCRows[0].toUsers = [];
                this.notificationCCRows[0].notis = [];
                this.notificationCCRows[0].ccUsers = [];

                let currentUser = this.localAzureUsers.find(user => user.id === this.azureId);

                if (currentUser) {
                    this.notificationCCRows[0].toUsers = [currentUser];
                }

                this.$emit('notisupdated', []);
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onNotificationsChange(evt, rowId) {
                let row = this.notificationCCRows.find(row => row.id === rowId);

                if (row) {
                    if (row.notis?.length > 1) {
                        if (row.notis[row.notis.length - 1].displayName !== 'All Notifications') {
                            row.notis = row.notis.filter(noti => noti.displayName !== 'All Notifications');
                        } else {
                            row.notis = row.notis.filter(noti => noti.displayName === 'All Notifications');
                        }
                    }

                    this.$emit('notisupdated', row.notis);
                }
            },
            onNotiFilterChange(evt, rowId) {
                let row = this.notificationCCRows.find(row => row.id === rowId);

                if (row) {
                    row.filteredNotis = filterBy(cloneDeep(this.notificationTemplates), evt.filter);
                }
            },
            onRequestError(errorTitle, errorMsg) {
                this.loading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSaveCCRows() {
                this.loading = true;

                let ccSettings = [];

                this.notificationCCRows.forEach(row => {
                    let toUsers = [];
                    let ccUsers = [];
                    let notis = [];

                    if (row.toUsers.some(toUser => toUser.name === 'All Users')) {
                        toUsers = this.localAzureUsers.filter(user => user.name !== 'All Users');
                    } else {
                        toUsers = row.toUsers;
                    }

                    if (row.notis.some(noti => noti.displayName === 'All Notifications')) {
                        notis = [null]
                    } else {
                        notis = row.notis;
                    }

                    ccUsers = row.ccUsers;

                    toUsers.forEach(user => {
                        notis.forEach(noti => {
                            ccUsers.forEach(ccUser => {
                                ccSettings.push({
                                    guid: null,
                                    azure_id: null,
                                    email_address: user.email,
                                    cc_address: ccUser.email,
                                    template_id: noti?.id ?? null,
                                    loan_number: null,
                                    type: 'To'
                                })
                            })
                        })
                    })
                });

                this.saveCCSettings(ccSettings);
            },
            onToUsersChange(evt, rowId) {
                let row = this.notificationCCRows.find(row => row.id === rowId);

                if (row) {
                    if (row.toUsers?.length > 1) {
                        if (row.toUsers[row.toUsers.length - 1].name !== 'All Users') {
                            row.toUsers = row.toUsers.filter(noti => noti.name !== 'All Users');
                        } else {
                            row.toUsers = row.toUsers.filter(noti => noti.name === 'All Users');
                        }
                    }
                }
            },
            onUserFilterChange(evt, rowId) {
                let row = this.notificationCCRows.find(row => row.id === rowId);

                if (row) {
                    row.filteredUsers = filterBy(cloneDeep(this.localAzureUsers), evt.filter);
                }
            },
            async saveCCSettings(request) {
                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpsertNotificationCCSettings(request, tokenResponse.accessToken)
                    .then(res => {
                        this.loading = false;
                        this.onClearCCRows();

                        this.showNotification('Settings saved successfully');
                    }).catch(error => {
                        let errorTitle = 'Failed to Save CC Settings';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                }
            }
        },
        components: {
            'k-loader': Loader,
            'k-multiselect': MultiSelect,
            'k-input': Input,
            'k-button': Button,
            'k-dialog': Dialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade
        }
    }
</script>

<style>
    @import '/src/assets/css/notificationCC.css';
</style>