<template>
    <div class="notification-management-view">
        <div class="view-title-container">
            <div class="view-title-icon">
                <i class="fa-sharp fa-solid fa-envelope"></i>
            </div>
            <div>
                <h2 class="view-title">Notification Management</h2>
            </div>
        </div>
        <k-tabstrip :selected="selectedTab" @select="onTabSelected" :tabs="notificationTabs" class="noti-management-tabstrip">
            <template v-slot:subscriptionManagement>
                <div class="view-components-container" v-if="selectedTab === 0">
                    <subscription-management />
                </div>
            </template>
            <template v-slot:createSettings>
                <div class="view-components-container" v-if="selectedTab === 1">
                    <div class="view-component cc-inputs">
                        <notification-cc :added-notifications="addedNotifications"
                                         :notifications-added="notificationsAdded"
                                         @notisloaded="assignNotifications"
                                         :azure-users="azureUsers"
                                         @notisupdated="onNotificationsUpdated"></notification-cc>
                    </div>
                    <div class="view-component noti-pipeline">
                        <notifications-pipeline v-if="allNotifications.length > 0"
                                                :notifications="allNotifications" 
                                                @notificationadded="onNotificationAdded"></notifications-pipeline>
                    </div>
                </div>
            </template>
            <template v-slot:editSettings>
                <edit-notification-cc  v-if="selectedTab === 2"
                                      :azure-users="azureUsers"
                                      :notifications="allNotifications"></edit-notification-cc>
            </template>
        </k-tabstrip>
    </div>
</template>

<script>
    import { TabStrip } from '@progress/kendo-vue-layout';

    import notificationCC from '../components/notificationCC.vue';
    import notificationsPipeline from '../components/notificationsPipeline.vue';
    import editNotificationCC from '../components/editNotificationCC.vue';
    import subscriptionManagement from '../components/subscriptionManagement.vue';

    import apiService from '../api/api.service.js';
    import auth from '../authConfig.js';

    export default {
        name: 'NotificationManagement',
        data() {
            return {
                addedNotifications: [],
                allNotifications: [],
                azureId: null,
                azureUsers: [],
                notificationsAdded: false,
                notificationTabs: [
                    {
                        title: 'Subscription Management',
                        content: 'subscriptionManagement'
                    },
                    {
                        title: 'Create CC Settings',
                        content: 'createSettings'
                    },
                    {
                        title: 'Edit CC Settings',
                        content: 'editSettings'
                    }
                ],
                selectedTab: 0
            }
        },
        async mounted() {
            const msalAppInstance = await auth.init();

            msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {
                if (!tokenRes) {
                    if (!auth.user()) {
                        auth.login();
                    }
                }

                this.azureId = localStorage.getItem('azure_id');

                if (!this.azureId) return;

                this.getUsersByOrgId();
            });
        },
        methods: {
            assignAzureUsers(usersData) {
                this.azureUsers = usersData.map(user => {
                    return {
                        name: user.displayName,
                        email: user.email,
                        id: user.id
                    }
                });
            },
            assignNotifications(notis) {
                this.allNotifications = notis;
            },
            async getUsersByOrgId() {
                const tokenResponse = await auth.acquireTokenResponse(); 

                //testing azure ids...
                //this.azureId = '37a013ac-f5f0-46c1-8734-5e855474a4d4' //Jared Peterson
                //this.azureId = '11127588-9290-464e-8d06-3e14fec2b0ae' //Marty Schaefer
                //this.azureId = 'd588e053-971a-4e87-8909-58dc9e36880b' //Doug Welch

                return apiService.GetUsersByOrgIdByRole(this.azureId, tokenResponse.accessToken)  
                    .then(res => {
                        if (res.data?.data) {
                            this.assignAzureUsers(res.data.data);
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch Users';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            onNotificationAdded(notification) {
                this.addedNotifications = [...this.addedNotifications, notification];

                this.notificationsAdded = true;

                setTimeout(() => {
                    this.notificationsAdded = false;
                }, 2000)
            },
            onNotificationsUpdated(notis) {
                this.addedNotifications = notis.filter(noti => noti.displayName !== 'All Notifications');
            },
            onTabSelected(evt) {
                this.selectedTab = evt.selected;
            }
        },
        components: {
            'k-tabstrip': TabStrip,
            'notification-cc': notificationCC,
            'notifications-pipeline': notificationsPipeline,
            'edit-notification-cc': editNotificationCC,
            'subscription-management': subscriptionManagement
        }
    }
</script>

<style>
    @import '/src/assets/css/notificationManagementView.css';
</style>