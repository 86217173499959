<template>
  <div class="command-center-container" style="">
    <div class="single-card-container">
      <card :card-type="'tabbedGrids'"
            title="Notifications"
            :columns="[dailyFeedSentColumns, dailyFeedReceivedColumns]"
            :grid-data="[dailyFeedSentData, dailyFeedReceivedData]"
            :tabs="tabs"
            :columns-loaded="dailyFeedSentLoaded"
            @row-clicked="showNotificationDialog" />
    </div>
    <div class="card-container">
      <div class="totals-container">
        <div class="column-header">
          <div class="header-icon">
            <i class="fa-duotone fa-solid fa-list-check"></i>
          </div>
          <div class="header-text">
            Borrower Tasks
          </div>
        </div>
        <div class="card-container-flex">
          <!--Telerik Donut-->
          <Chart :donut-center-render="'borrowerCenterRenderer'"
                 @seriesclick="chartClickHandler">
            <template v-slot:borrowerCenterRenderer>
              <span class="donut-center-text"><strong>{{borrowerTaskSum}}</strong></span>
            </template>
            <ChartArea :background="'#fff'" :height="donutChartAreaHeight" :width="donutChartAreaWidth" />
            <ChartSeries>
              <ChartSeriesItem :type="'donut'"
                               :data-items="borrowerSections"
                               :category-field="'label'"
                               :field="'value'"
                               :color-field="'color'"
                               :size="donutChartSize"
                               :hole-size="donutChartHoleSize">
              </ChartSeriesItem>
            </ChartSeries>
            <ChartLegend :visible="false" />
          </Chart>
          <div class="task-totals-section">
            <div class="task-total-container"
                 @click="showGridDialog(openTaskRequested, openTaskColumns, 'Outstanding Tasks', true)">
              <div class="task-title">
                <span>Outstanding</span>
              </div>
              <div class="task-total" style="color: #ed766b">
                {{openTaskRequestedTotal}}
              </div>
            </div>
            <div class="task-total-divider"></div>
            <div class="task-total-container"
                 @click="showGridDialog(openTaskPending, openTaskColumns, 'Pending Tasks', true)">
              <div class="task-title">
                <span>Pending</span>
              </div>
              <div class="task-total" style="color: #ede76b">
                {{openTaskPendingTotal}}
              </div>
            </div>
            <div class="task-total-divider"></div>
            <div class="task-total-container"
                 @click="showGridDialog(openSigningTaskData, openTaskColumns, 'Pending Borrower Signature', true)">
              <div class="task-title">
                <span>Pending Borrower Signature</span>
              </div>
              <div class="task-total" style="color: #398b87">
                {{openSigningTaskTotal}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="totals-container">
        <div class="column-header">
          <div class="header-icon">
            <i class="fa-duotone fa-solid fa-list-check"></i>
          </div>
          <div class="header-text">
            Pipeline Management
          </div>
        </div>
        <div class="card-container-flex">
          <!--Telerik Donut-->
          <Chart :donut-center-render="'pipelineCenterRenderer'"
                 @seriesclick="chartClickHandler">
            <template v-slot:pipelineCenterRenderer>
              <span class="donut-center-text" style=""><strong>{{pipelineManagementSum}}</strong></span>
            </template>
            <ChartArea :background="'#fff'" :height="donutChartAreaHeight" :width="donutChartAreaWidth" />
            <ChartSeries>
              <ChartSeriesItem :type="'donut'"
                               :data-items="pipelineManagementSections"
                               :category-field="'label'"
                               :field="'value'"
                               :color-field="'color'"
                               :size="donutChartSize"
                               :hole-size="donutChartHoleSize">
              </ChartSeriesItem>
            </ChartSeries>
            <ChartLegend :visible="false" />
          </Chart>
          <div class="task-totals-section">
            <div class="task-total-container"
                 @click="showDisclosures">
              <div class="task-title">
                <span>Pending LO Signature</span>
              </div>
              <div class="task-total" style="color: #a4dad7">
                {{disclosuresTotal}}
              </div>
            </div>
            <div class="task-total-divider"></div>
            <div class="task-total-container"
                 @click="showGridDialog(newAppsData, newAppsColumns, 'New Applications')">
              <div class="task-title">
                <span>New Applications</span>
              </div>
              <div class="task-total" style="color: #051d3a">
                {{newAppsTotal}}
              </div>
            </div>
            <div class="task-total-divider"></div>
            <div class="task-total-container"
                 @click="showGridDialog(expiringLocksData, expiringLocksColumns, 'Expiring Locks')">
              <div class="task-title">
                <span>Expiring Locks</span>
              </div>
              <div class="task-total" style="color: #ed766b">
                {{expiringLocksTotal}}
              </div>
            </div>
            <div class="task-total-divider"></div>
            <div class="task-total-container"
                 @click="showGridDialog(unlockedLoansData, unlockedLoansColumns, 'Unlocked Loans')">
              <div class="task-title">
                <span>Unlocked Loans</span>
              </div>
              <div class="task-total" style="color: #398b87">
                {{unlockedLoansTotal}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="totals-container">
        <div class="column-header">
          <div class="column-header-container">
            <div class="header-icon">
              <i class="fa-duotone fa-solid fa-list-check"></i>
            </div>
            <div class="header-text">
              Internal Tasks
            </div>
          </div>
          <div class="column-header-container">
            <k-switch
              v-if="isGrouped"
              :onLabel="'All'"
              :offLabel="'Mine'"
              :size="'medium'"
              :checked="taskSwitchChecked"
              @change="onTaskSwitchChange"
            />         
          </div>          
        </div>
        <div class="card-container-flex">
          <!--Telerik Donut-->
          <Chart :donut-center-render="'internalCenterRenderer'"
                 @seriesclick="chartClickHandler">
            <template v-slot:internalCenterRenderer>
              <span class="donut-center-text"><strong>{{internalTaskSum}}</strong></span>
            </template>
            <ChartArea :background="'#fff'" :height="donutChartAreaHeight" :width="donutChartAreaWidth" />
            <ChartSeries>
              <ChartSeriesItem :type="'donut'"
                               :data-items="internalSections"
                               :category-field="'label'"
                               :field="'value'"
                               :color-field="'color'"
                               :size="donutChartSize"
                               :hole-size="donutChartHoleSize">
              </ChartSeriesItem>
            </ChartSeries>
            <ChartLegend :visible="false" />
          </Chart>
          <div class="task-totals-section">
            <div class="task-total-container"
                 @click="showGridDialog(internalTaskRequested, internalTaskColumns, 'Outstanding Internal Tasks')">
              <div class="task-title">
                <span>Outstanding</span>
              </div>
              <div class="task-total" style="color: #ed766b">
                {{internalTaskRequestedTotal}}
              </div>
            </div>
            <div class="task-total-divider"></div>
            <div class="task-total-container"
                 @click="showGridDialog(internalTaskPending, internalTaskColumns, 'Open Internal Tasks')">
              <div class="task-title">
                <span>Open</span>
              </div>
              <div class="task-total" style="color: #ede76b">
                {{internalTaskPendingTotal}}
              </div>
            </div>
            <div class="task-total-divider"></div>
            <div class="task-total-container"
                 @click="showGridDialog(followUpToday, internalTaskColumns, 'Due Today')">
              <div class="task-title">
                <span>Due Today</span>
              </div>
              <div class="task-total" style="color: #051d3a">
                {{followUpTodayTotal}}
              </div>
            </div>
            <div class="task-total-divider"></div>
            <div class="task-total-container"
                 @click="showGridDialog(pastDue, internalTaskColumns, 'Past Due')">
              <div class="task-title">
                <span>Past Due</span>
              </div>
              <div class="task-total" style="color: #b3130b">
                {{pastDueTotal}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="notification-container">
      <k-dialog v-if="notificationDialog"
                :title="'Notification'"
                @close="(()=>notificationDialog = !notificationDialog)"
                @overlay-click="(()=>notificationDialog = !notificationDialog)">
        <div class="html-cont" v-html="this.dialogData.message">
        </div>
      </k-dialog>

    </div>
    <k-dialog v-if="gridDialog"
              :title="pipelineTitle"
              @close="onGridDialogClose">
      <kbutton v-if="sendNotiButton" @click="onSendTaskNotification">
        <i class="fa-duotone fa-solid fa-envelope"></i> Send Task Notifications
      </kbutton>
      <div class="dialog-grid-container">
        <Grid :filterable="true"
              :filter="filter"
              @filterchange="filterChange"
              :cell-render="pipelineCell"
              :selectable="{ mode: 'multiple'} "
              :selected-field="selectedField"
              @selectionChange="onSelectionChange"
              @headerselectionchange="onHeaderSelectionChange"
              :sortable="true"
              :sort="sort"
              @sortchange="sortChangeHandler"
              :style="{ height: '100%' }"
              :data-items="result"
              :columns="gridDialogColumns"
              @rowclick="navigateToLoan">
          <!-- Grid Toolbar -->
          <GridToolbar>
            <div class="toolbar-container">
              <!-- Search Input -->
              <k-input class="search-input"
                       placeholder="Search in all columns..."
                       v-model="searchWord"
                       @input="onFilter"></k-input>
              <!-- Export Button -->
              <kbutton class="exportExcelButton"
                       :theme-color="'primary'"
                       @click="exportExcel">
                Export Excel
              </kbutton>
            </div>
          </GridToolbar>
          <template v-slot:pipelineCell="{props}">
            <td class="pipeline-cell">{{getNestedValue(props.field, props.dataItem)}}</td>
          </template>
          <template v-slot:checkBox="{props}">
            <td class="pipeline-cell">
              <checkbox :checked="props.dataItem.selected"
                        @change="onSelectionChange(props.dataItem)" />
            </td>
          </template>
          <template v-slot:dateCell="{props}">
            <td class="pipeline-cell">
              {{formatDate(props)}}
            </td>
          </template>
        </Grid>
      </div>
    </k-dialog>
  </div>

  <transition name="dialogfade">
    <k-dialog v-if="showConfirmDialog" :title="confirmTitle" class="consumer-dialog confirm-dialog" @close="toggleConfirmDialog">
      <p class="confirm-msg">{{ confirmMsg }}</p>
      <p v-if="confirmNote" class="confirm-note">Note: {{ confirmNote}}</p>
      <k-dialog-actions>
        <kbutton class="dialog__btn dialog__btn--close" v-on:click="toggleConfirmDialog">No</kbutton>
        <kbutton class="dialog__btn dialog__btn--save" v-on:click="onActionConfirmed">Yes</kbutton>
      </k-dialog-actions>
    </k-dialog>
  </transition>
  <transition name="dialogfade">
    <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
      <p class="error-dialog__msg">{{ errorMsg }}</p>
    </k-dialog>
  </transition>

  <k-notification-group class="conditions__notification-container consumer">
    <k-fade :appear="successNotification">
      <k-notification v-if="successNotification"
                      :type="{ style: 'success', icon: true }"
                      :closable="true"
                      @close="onCloseNotification('successNotification')">
        <span>{{ notificationMsg }}</span>
      </k-notification>
    </k-fade>
  </k-notification-group>
</template>

<script>
  import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
  import { Checkbox, Input } from "@progress/kendo-vue-inputs";
  import card from '../components/dashboardCard.vue';
  import apiService from '../api/api.service.js';
  import auth from '../authConfig.js';
  import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
  import { Button } from '@progress/kendo-vue-buttons';
  import { TabStrip } from '@progress/kendo-vue-layout';
  import { orderBy } from '@progress/kendo-data-query';
  import 'vue-css-donut-chart/dist/vcdonut.css';
  import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
  import { Fade } from "@progress/kendo-vue-animation";
  import { saveExcel } from '@progress/kendo-vue-excel-export';
  import { process } from "@progress/kendo-data-query";


  import { Switch } from "@progress/kendo-vue-inputs";

  import "hammerjs";

  import { VcDonut } from 'vue-css-donut-chart';

  import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  } from "@progress/kendo-vue-charts";
  const labelContent = function (e) {
  return `${e.category}: \n ${e.value}%`;
  };
  export default {
    data(){
      return {
        azureId: '',
        borrowerTaskSum: 0,
        checkBox: 'checkBox',
        confirmMsg: '',
        confirmNote: '',
        confirmFunction: null,
        confirmTitle: 'Please Confirm',
        dailyFeedLoaded: false,
        dailyFeedReceivedData: [],
        dailyFeedReceivedLoaded: false,
        dailyFeedReceivedSize: 0,
        dailyFeedSentData: [],
        dailyFeedSentLoaded: false,
        dailyFeedSentSize: 0,
        dateCell: "dateCell",
        dialogData: [],
        disclosuresData: [],
        disclosuresLoaded: false,
        disclosuresTotal: 0,
        donutChartSize: 25,
        donutChartHoleSize: 60,
        errorMsg: '',
        errorTitle: '',
        expiringLocksData: [],
        expiringLocksLoaded: false,
        expiringLocksTotal: 0,
        fetchParams: [],
        filter: {
          logic: 'and',
          filters: []
        },
        followUpToday: [],
        followUpTodayChartShare: 0,
        followUpTodayTotal: 0,
        gridDialog: false,
        gridDialogColumns: [],
        gridDialogData: [],
        internalTasksData: [],
        internalTasksLoaded: [],
        internalTaskPending: [],
        internalTaskPendingChartShare: 0,
        internalTaskPendingTotal: 0,
        internalTaskRequested: [],
        internalTaskRequestedChartShare: 0,
        internalTaskRequestedTotal: 0,
        internalTaskSum: 0,
        isGrouped: false,
        loanBorrowers: [],
        loanData: [],
        newAppsColumns: [
          { field: "f364", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, filterable: true },
          { field: "borrower_name", title: "Borrower Name", className: "clickable", sortable: true, columnMenu: true, filterable: true },
          { field: "f11", title: "Address", className: "clickable", sortable: true, columnMenu: true, filterable: true },
          { field: "logmsdatestarted", title: "Date", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true }
        ],
        newAppsData: [],
        newAppsLoaded: false,
        newAppsTotal: 0,
        notificationDialog: false,
        notificationMsg: '',
        openTaskData: [],
        openTaskRequested: [],
        openTaskRequestedChartShare: 0,
        openTaskRequestedTotal: 0,
        openTaskPending: [],
        openTaskPendingChartShare: 0,
        openTaskPendingTotal: 0,
        openTaskLoaded: false,
        outstandingTasksLoaded: false,
        openSigningTaskChartShare: 0,
        openSigningTaskData: [],
        openSigningTaskTotal: 0,
        pastDue: [],
        pastDueChartShare: 0,
        pastDueTotal: 0,
        pendingTasksLoaded: false,
        pipelineCell: "pipelineCell",
        pipelineManagementSum: 1,
        pipelineTitle: "",
        searchWord: '',
        sendNotiButton: false,
        selected: 0,
        selectedField: 'selected',
        showErrorDialog: false,
        showConfirmDialog: false,
        sort: [],
        successNotification: false,
        tabs: [
          {
            title: 'Sent (0)',
            content: 'grid'
          },
          {
            title: 'Received (0)',
            content: 'grid'
          }
        ],
        tasksToSend: [],
        token: '',
        unlockedLoansData: [],
        unlockedLoansLoaded: false,
        unlockedLoansTotal: 0,
        userRoles: [],
        windowWidth: window.innerWidth
      }
    },
    components: {
      'card': card,
      Chart,
      ChartArea,
      ChartLegend,
      ChartSeries,
      ChartSeriesItem,
      ChartSeriesLabels,
      checkbox: Checkbox,
      'dialog-actions-bar': DialogActionsBar,
      'k-fade': Fade,
      'k-switch': Switch,
      'Grid': Grid,
      'GridToolbar': GridToolbar,
      'k-dialog': Dialog,
      'k-input': Input,
      'k-notification': Notification,
      'k-dialog-actions': DialogActionsBar,

      'k-notification-group': NotificationGroup,
      'kbutton': Button,
      TabStrip,
      'vc-donut': VcDonut
    },
    computed: {
      selectedDataItems() {
        return this.gridDialogData.filter((item) => item.selected === true);
      },
      areAllSelected() {
        return (
          this.selectedDataItems.length === this.gridDialogData.length &&
          this.selectedDataItems.length !== 0
        );
      },
      borrowerSections(){
        return [
          {"label": "Outstanding Tasks", "value": this.openTaskRequestedChartShare, "color": "#ed766b"},
          {"label": "Pending Tasks", "value": this.openTaskPendingChartShare, "color": "#ede76b"},
          {"label": "Pending Borrower Signature", "value": this.openSigningTaskChartShare, "color": "#398b87"}
        ]
      },
      internalSections() {
        return [
          { "label": "Outstanding Internal Tasks", "value": this.internalTaskRequestedChartShare, "color": "#ed766b" },
          { "label": "Open Internal Tasks", "value": this.internalTaskPendingChartShare, "color": "#ede76b" },
          //{ "label": "Accepted Internal Tasks", "value": this.internalAcceptedTaskChartShare, "color": "#398b87" },
          { "label": "Due Today", "value": this.followUpTodayChartShare, "color": "#051d3a" },
          { "label": "Past Due", "value": this.pastDueChartShare, "color": "#b3130b"}

        ]
      },
       disclosuresChartShare(){
        return this.disclosuresTotal / this.pipelineManagementSum;
       },
       donutChartSize(){
         if (this.windowWidth <= 1024){
            return 25;
         }
         else{
            return 20;
         }
       },

       donutChartHoleSize(){
         if (this.windowWidth <= 1024){
            return 40;
         }
         else{
          return 40;
         }
       },
       donutChartAreaHeight(){
         if (this.windowWidth <= 1024){
           return 160;
         }
         else{
           return 140;
         }
       },

       donutChartAreaWidth(){
        if (this.windowWidth <= 1024){
          return 160;
        }
        else{
          return 200;
        }
      },
      filteredTasksToSend() {
        let result = [];
        this.tasksToSend.forEach((task) => {
          if (!result.includes(task.id)) {
            result.push(task.id)
          }
        });
        return result
      },
      
      smallColumnWidth(){
        if (this.windowWidth <= 600){
          return '70px';          
        }
        else
          return '100px';
      },
      dailyFeedReceivedColumns(){
        if (this.windowWidth <= 430){
          const columns = [
          {field: "loanNumber", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, width: this.smallColumnWidth, filterable: true},
            { field: "borrowerName", title: "Borrower Name", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "subject", title: "Subject", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "sender", title: "Sent by", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
          ];
          return columns;
        } else {
          const columns = [
            { field: "loanNumber", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, width: this.smallColumnWidth, filterable: true },
            { field: "borrowerName", title: "Borrower Name", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "subject", title: "Subject", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "sender", title: "Sent by", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "sent", title: "Date", className: "clickable", sortable: true, columnMenu: true, width: this.smallColumnWidth, filterable: true },
            { field: "sentTime", title: "Time", className: "clickable", sortable: true, columnMenu: true, width: this.smallColumnWidth, filterable: true }
          ];
          return columns;
        }

      },
      dailyFeedSentColumns(){
        if (this.windowWidth <= 430){
          const columns = [
            { field: "loanNumber", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, width: this.smallColumnWidth, filterable: true },
            { field: "ContactName", title: "Recipient Name", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "ContactRole", title: "Role", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "subject", title: "Subject", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "receiver", title: "Sent to", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
          ];
          return columns;
        } else {
          const columns = [
            { field: "loanNumber", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, width: this.smallColumnWidth, filterable: true },
            { field: "contactName", title: "Recipient Name", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "contactRole", title: "Role", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "subject", title: "Subject", cell: 'subjectCell', className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "receiver", title: "Sent to", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "sent", title: "Date", className: "clickable", sortable: true, columnMenu: true, width: this.smallColumnWidth, filterable: true },
            { field: "sentTime", title: "Time", className: "clickable", sortable: true, columnMenu: true, width: this.smallColumnWidth, filterable: true }
          ];
          return columns;
        }

      },
      expiringLocksChartShare(){
        return this.expiringLocksTotal / this.pipelineManagementSum;
      },
      expiringLocksColumns() {
        if (this.windowWidth <= 430) {
          const columns = [
            { field: "name", title: "Name", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f2149", title: "Lock Date", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
            { field: "f2151", title: "Lock Expiration Date", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
          ];
          return columns;
        } else {
          const columns = [
            { field: "f364", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "name", title: "Name", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f384", title: "Loan Purpose", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f2", title: "Loan Amount", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f3", title: "Interest Rate", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f2149", title: "Lock Date", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
            { field: "f1401", title: "Loan Program", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f2151", title: "Lock Expiration Date", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
            { field: "msstatus", title: "Last Completed Milestone", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "cust17fv", title: "Est. Closing Date", sortable: true, columnMenu: true, cell: "dateCell", filterable: true }

            
            ];
          return columns;
        }
      },
      newAppsChartShare(){
        return this.newAppsTotal / this.pipelineManagementSum;
      },
      openTaskColumns() {
        if (this.windowWidth <= 430) {
          const columns = [
            {
              field: 'selected',
              width: '45px',
              columnMenu: false,
              headerSelectionValue: this.areAllSelected,
              cell: 'checkBox'
            },
            { field: "title", title: "Title", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "borrower_name", title: "Borrower Name", filterable: true, className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "created", title: "Date Created", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
          ];
          return columns;
        } else {
          const columns = [
            {
              field: 'selected',
              width: '45px',
              columnMenu: false,
              headerSelectionValue: this.areAllSelected,
              cell: 'checkBox'
            },
            { field: "f364", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "assignedto", title: "Assigned To", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "borrower_name", title: "Borrower Name", filterable: true, className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "title", title: "Title", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "created", title: "Date Created", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
            { field: "age", title: "Task Age", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "cust17fv", title: "Est. Closing Date", sortable: true, columnMenu: true, cell: "dateCell", filterable: true }
            ];
          return columns;
        }
      },
      internalTaskColumns() {
        if (this.windowWidth <= 430) {
          const columns = [

            { field: "title", title: "Title", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "AssignedTo", title: "Assigned To", filterable: true, className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "Created", title: "Date Created", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
          ];
          return columns;
        } else {
          const columns = [
           
            { field: "loannumber", title: "Loan Number", sortable: true, columnMenu: false, filterable: true },
            { field: "borrowername", title: "Borrower Name", sortable: true, columnMenu: false, filterable: true },
            { field: "title", title: "Title", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "assignedto", title: "Assigned To", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "created", title: "Date Created", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
            { field: "followupdate", title: "Follow Up Date", className: "clickable", sortable: true, columnMenu: true, cell: "dateCell", filterable: true },
            { field: "status", title: "Status", className: "clickable", sortable: true, columnMenu: true, filterable: true},
            { field: "notes", title: "Notes", sortable: false, columnMenu: false, filterable: true },
          ];
          return columns;
        }
      },
      pipelineManagementSections(){
        return [
          {"label": "Pending LO Signature", "value": this.disclosuresChartShare, "color": "#a4dad7"},
          {"label": "New Applications", "value": this.newAppsChartShare, "color": "#051d3a"},
          {"label": "Expiring Locks", "value": this.expiringLocksChartShare, "color": "#ed766b"},
          {"label": "Unlocked Loans", "value": this.unlockedLoansChartShare, "color": "#398b87"}

        ]
       },
      result: {
        get: function (){
          //const filteredData = this.gridDialogData;
          const filteredData = process(this.gridDialogData, {filter: this.filter}).data;
          return orderBy(filteredData, this.sort);
        }
      },
      tabs() {
        if (this.dailyFeedSentLoaded && this.dailyFeedReceivedLoaded){
          return [
            {
              title: `Sent (${this.dailyFeedSentSize})`,
              content: 'grid'
            },
            {
              title: `Received (${this.dailyFeedReceivedSize})`,
              content: 'grid'
            }
          ];
        }
        return [
          {
            title: 'Sent (0)',
            content: 'grid'
          },
          {
            title: 'Received (0)',
            content: 'grid'
          }
        ]
      },
      unlockedLoansChartShare(){
        return this.unlockedLoansTotal / this.pipelineManagementSum;
      },
      unlockedLoansColumns() {
        if (this.windowWidth <= 430) {
          const columns = [
            { field: "f364", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "name", title: "Name", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f2", title: "Loan Amount", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f3", title: "Interest Rate", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "msstatus", title: "Last Completed Milestone", className: "clickable", sortable: true, columnMenu: true, filterable: true },
          ];
          return columns;
        } else {
          const columns = [
            { field: "f364", title: "Loan Number", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "name", title: "Name", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f384", title: "Loan Purpose", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f2", title: "Loan Amount", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f3", title: "Interest Rate", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "f1401", title: "Loan Program", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "msstatus", title: "Last Completed Milestone", className: "clickable", sortable: true, columnMenu: true, filterable: true },
            { field: "cust17fv", title: "Est. Closing Date", sortable: true, columnMenu: true, cell: "dateCell", filterable: true }


          ];
          return columns;
        }
      },
    },
    methods: {
      addRemoveTask(task) {
        console.log(task);
        const index = this.tasksToSend.findIndex(t => t === task);
        if (index !== -1) {
          this.tasksToSend.splice(index, 1);
        } else {
          console.log(task);
          this.tasksToSend.push(task);
        }
      },
      chartClickHandler(e){
        if (e.dataItem.label === 'Outstanding Tasks') {
          this.showGridDialog(this.openTaskRequested, this.openTaskColumns, 'Outstanding Tasks');
        } else if (e.dataItem.label === 'Pending Tasks') {
          this.showGridDialog(this.openTaskPending, this.openTaskColumns, 'Pending Tasks');
        } else if (e.dataItem.label === 'Pending Borrower Signature') {
          this.showGridDialog(this.openSigningTaskData, this.openTaskColumns, 'Pending Borrower Signature');
        } else if (e.dataItem.label === 'Pending LO Signature') {
          this.showDisclosures;
        } else if (e.dataItem.label === 'New Applications') {
          this.showGridDialog(this.newAppsData, this.newAppsColumns, 'New Applications');
        } else if (e.dataItem.label === 'Expiring Locks') {
          this.showGridDialog(this.expiringLocksData, this.expiringLocksColumns, 'Expiring Locks');
        } else if (e.dataItem.label === 'Unlocked Loans') {
          this.showGridDialog(this.unlockedLoansData, this.unlockedLoansColumns, 'Unlocked Loans');
        } else if (e.dataItem.label === 'Outstanding Internal Tasks') {
          this.showGridDialog(this.internalTaskRequested, this.internalTaskColumns, 'Outstanding Internal Tasks')
        } else if (e.dataItem.label === 'Due Today') {
          this.showGridDialog(this.followUpToday, this.internalTaskColumns, 'Due Today')
        } else if (e.dataItem.label === 'Past Due') {
          this.showGridDialog(this.pastDue, this.internalTaskColumns, 'Past Due')
        } else if (e.dataItem.label === 'Open Internal Tasks') {
          this.showGridDialog(this.internalTaskPending, this.internalTaskColumns, 'Open Internal Tasks')
        }
      },
      async checkGrouping(azureId){
        var groupings = await apiService.GetGrouping(azureId, this.token.accessToken);
        this.isGrouped = groupings.data.data.length > 0 ? true : false;

      },
      exportExcel() {
        const excelColumns = this.gridDialogColumns.map(col => {
          return {
            field: col.field,
            title: col.title || col.field,
            width: col.width,
            format: col.format || undefined,
          };
        });
        const dataToExport = this.gridDialogData;
        saveExcel({
          data: dataToExport,
          fileName: `${this.pipelineTitle}.xlsx`,
          columns: excelColumns,
        });
      },
      async fetchData(dataContainer, dataLoaded, apiFunction, ...apiArgs) {
        try {
          const tokenResponse = await auth.acquireTokenResponse(); // Get Auth Token
          if (!tokenResponse) throw new Error("Failed to acquire token response");
          const response = await apiFunction(...apiArgs, tokenResponse.accessToken);

          const data = response.data;
          if (data && Array.isArray(data.data)) {
            this[dataContainer] = data.data;
          } else {
            console.error("No valid data received from API");
            this[dataContainer] = [];
          }
        }
        catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          this[dataLoaded] = true;
        }
      },
      filterChange(e) {
        this.filter = e.filter;        
      },
      formatDate(props) {
        const date = this.getNestedValue(props.field, props.dataItem);
        if (date) {
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();

          // Return formatted mm/dd/yyyy
          return `${month}/${day}/${year}`;
        }
        return null;
      },
      getNestedValue: function(fieldName, dataItem) {
        const path = fieldName.split('.');
        let data = dataItem;
        path.forEach((p) => {
          data = data ? data[p] : undefined;
        });
        return data;
      },
      getScreenWidth() {
        this.windowWidth = window.innerWidth;
      },
      isToday(dateString) {
        const today = new Date();
        const inputDate = new Date(dateString);

        return (
          inputDate.getDate() === today.getDate() &&
          inputDate.getMonth() === today.getMonth() &&
          inputDate.getFullYear() === today.getFullYear()
        );
      },
      isPastDue(dateString) {
        const inputDate = new Date(dateString);
        const currentDate = new Date();

        // Normalize dates to ignore time
        inputDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        return inputDate < currentDate;
      },
      navigateToLoan(dataItem) {
        var swiftURL = process.env.VUE_APP_SWIFT_URL;
        if (this.pipelineTitle.includes("Internal") || this.pipelineTitle.includes("Due Today") || this.pipelineTitle.includes("Past Due")) {
          window.open(`${dataItem.dataItem.tasklink}`, '_blank');
        }
        else {
          if (dataItem.f364) {
            window.open(`https://swift.goldstarfinancial.com/ConsumerPortal?id=${dataItem.id}&swiftprocessing=true&taskId=${dataItem.task_id}&taskOpen=true`, '_blank'); //open in new tab
          }
          else {
            console.log(dataItem.dataItem.tasklink);
            window.open(`https://swift.goldstarfinancial.com/ConsumerPortal?id=${dataItem.dataItem.id}&swiftprocessing=true&taskId=${dataItem.dataItem.task_id}&taskOpen=true`, '_blank'); //open in new tab
          }
        }
      },
      onActionConfirmed() {
        this[this.confirmFunction]();
        this.areAllSelected = false;
        this.gridDialogData.forEach(t => t.selected = false);
        this.toggleConfirmDialog();
      },
      onFilter() {
        const keyword = this.searchWord.toLowerCase().trim();
        if (!keyword) {
          // Reset the grid data to its original state
          this.gridDialogData = this.originalGridData || [];
          return;
        }
        // Get the fields of the available columns
        const searchableFields = this.gridDialogColumns.map(col => col.field);
        // Perform filtering only on available columns
        this.gridDialogData = this.originalGridData.filter((item) =>
          searchableFields.some((field) => {
            const value = item[field];
            return value && String(value).toLowerCase().includes(keyword);
          })
        );
      },
      onGridDialogClose() {
        this.gridDialogData.forEach(t => t.selected = false);
        this.tasksToSend = [];
        this.gridDialog = !this.gridDialog;
        this.sendNotiButton = false;
      },
      onHeaderSelectionChange(event) {
        if (event.event){
          let checked = event.event.target.checked;
          this.gridDialogData = this.gridDialogData.map((item) => { return { ...item, selected: checked } });
          this.gridDialogData.forEach((item) => {
            if (item.selected) {
              const index = this.tasksToSend.findIndex(t => t.task_id === item.task_id);
              if (index === -1) {
                this.tasksToSend.push(item)
              }
            } else {
              const index = this.tasksToSend.findIndex(t => t.task_id === item.task_id);
              if (index !== -1) {
                this.tasksToSend.splice(index, 1);
              }
            }
          })
        }        
      },
      onRequestError(errorTitle, errorMsg) {
        //this.isLoading = false;

        this.errorTitle = errorTitle;

        if (errorMsg) {
          this.errorMsg = errorMsg;
        } else {
          this.errorMsg = 'Please try again';
        }

        this.showErrorDialog = true;
      },
      onSendTaskNotification() {
        this.confirmMsg = 'Are you sure you would like to send all borrowers on file a notification of all Requested tasks?';
        this.confirmNote = 'Borrowers must have a valid email on file to receive the notification';
        this.confirmFunction = 'sendTaskNotification';
        this.toggleConfirmDialog();
      },
      onSelectionChange(dataItem) {
        dataItem[this.selectedField] = !dataItem[this.selectedField];
        console.log(dataItem);
        this.addRemoveTask(dataItem);
      },
      onSelect(e){
        this.selected=e.selected;
      },
      onTaskSwitchChange(){
        this.taskSwitchChecked = !this.taskSwitchChecked;
        this.taskSwitchChecked ? this.setCookie('taskSwitch', 'on') : this.setCookie('taskSwitch', 'off');
        this.internalTasksLoaded = false;
        this.followUpTodayTotal = 0;
        this.internalTasksData = [];
        this.internalTaskPending = [];
        this.internalTaskRequested = [];
        this.followUpToday = [];
        this.internalTaskPendingTotal = 0;
        this.internalTaskRequestedTotal = 0;
        this.pastDue = [];
        this.pastDueTotal = 0;
        if (this.taskSwitchChecked) {        
          this.fetchData('internalTasksData', 'internalTasksLoaded', apiService.GetGroupingInternalTasks, this.azureId)
          .then(() => this.processInternalTasks());
        } else {
            this.fetchData('internalTasksData', 'internalTasksLoaded', apiService.GetInternalTasks, this.azureId)
            .then(() => this.processInternalTasks());
        }
      },
      async processDailyFeed(){
        for (let i = this.dailyFeedSentData.length - 1; i >= 0; i--) {
          if (this.dailyFeedSentData[i].sent_true==="FALSE") {
            const item = this.dailyFeedSentData.splice(i, 1)[0];
            this.dailyFeedReceivedData.unshift(item);
          }
        }
        this.dailyFeedSentSize = this.dailyFeedSentData.length;
        this.dailyFeedReceivedSize = this.dailyFeedReceivedData.length;
        this.dailyFeedSentLoaded = true;
        this.dailyFeedReceivedLoaded = true;
      },
      async processInternalTasks() {
        this.internalTaskSum = this.internalTasksData.length;
        for (let i = this.internalTasksData.length - 1; i >= 0; i--) {
          const item = this.internalTasksData.splice(i, 1)[0];
          if (this.isToday(item.followupdate) && item.status !== "Completed") {
            this.followUpToday.unshift(item);
            this.followUpTodayTotal++;
          }
          if (this.isPastDue(item.followupdate) && item.status !== "Completed") {
            this.pastDue.unshift(item);
            this.pastDueTotal++;
          }
          if (item.status === "Pending") {
            //Have to do this because SOMEONE wanted to change what these were called after a bunch of stuff had already been built around what they used to be called
            item.status = "Open";

            this.internalTaskPending.unshift(item);
            this.internalTaskRequested.unshift(item);
            this.internalTaskRequestedTotal++;
            this.internalTaskPendingTotal++;
          }
          if (item.status === "Requested") {
            //Read the above comment
            item.status = "Assigned";

            this.internalTaskRequested.unshift(item);
            this.internalTaskRequestedTotal++;
          } else if (item.status === "Completed") {
          this.internalTaskSum--;
          }
          if (item.followupdate) {
            item.followupdate = new Date(item.followupdate);
          }
          item.created = item.created ? new Date(item.created) : null;
          this.internalTaskPendingChartShare = this.internalTaskPendingTotal / (this.internalTaskSum + this.followUpTodayTotal);
          this.internalTaskRequestedChartShare = this.internalTaskRequestedTotal / (this.internalTaskSum + this.followUpTodayTotal);
          this.followUpTodayChartShare = this.followUpTodayTotal / (this.internalTaskSum + this.followUpTodayTotal);
          this.pastDueChartShare = this.pastDueTotal / (this.internalTaskSum + this.pastDueTotal);
        }
      },
      async processOpenTasks() {
        this.borrowerTaskSum = this.openTaskData.length;
        for (let i = this.openTaskData.length - 1; i >= 0; i--) {
          const item = this.openTaskData.splice(i, 1)[0];
          item.created = item.created ? new Date(item.created) : null;
          item.cust17fv = item.cust17fv ? new Date(item.cust17fv) : null;
          if (item.status === "Pending") {
            this.openTaskPending.unshift(item);
            this.openTaskPendingTotal++;

          } else if (item.status === "Requested" && item.signingtask === "True") {
            this.openSigningTaskData.unshift(item);
            this.openSigningTaskTotal++;
          }
          else if (item.status === "Requested" && item.signingtask === "False") {
            this.openTaskRequested.unshift(item);
            this.openTaskRequestedTotal++;
          }
        }
        this.openTaskPendingChartShare = this.openTaskPendingTotal / this.borrowerTaskSum;
        this.openSigningTaskChartShare = this.openSigningTaskTotal / this.borrowerTaskSum;
        this.openTaskRequestedChartShare = this.openTaskRequestedTotal / this.borrowerTaskSum;
        this.outstandingTasksLoaded = true;
        this.pendingTasksLoaded = true;
      },
      async sendTaskNotification() {
        
        try {
          for (let t of this.filteredTasksToSend) {

            console.log("response " + this.token.accessToken);

            apiService.CreateManualBorrowerTaskNotifications(t, this.token.accessToken)
              .then(res => {
                //this.isLoading = false;
                this.showNotification('Notification(s) sent successfully');
              }).catch(error => {
                this.errorTitle = 'Failed to Send Notifications';

                if (error.response?.data) {
                  this.errorMsg = "Error: \n\n" + error.response?.data?.data;
                } else {
                  this.errorMsg = '';
                }

                this.onRequestError(this.errorTitle, this.errorMsg);
              });
          }
        } catch (ex) {
          console.error("Error fetching data:", ex);

        }
      },
      setCookie(id, value){
        document.cookie = id + '=' + value;
      },
      getCookie(id){
        let value = document.cookie.match('(^|;)?' + id + '=([^;]*)(;|$)');
        return value ? value[2] : null;
      },
      showDisclosures(){
        window.open('https://www.encompassloconnect.com/package-pipeline', '_blank', 'location=yes, scrollbars=yes,status=yes');
      },
      showGridDialog(gridData, gridColumns, pipelineTitle, notiButton) {
        this.originalGridData = [...gridData];
        this.gridDialog = true;
        this.gridDialogData = gridData;
        this.gridDialogColumns = gridColumns;
        this.pipelineTitle = pipelineTitle;
        this.sendNotiButton = notiButton
      },
      showNotification(notificationMsg) {        
        this.notificationMsg = notificationMsg;

        this.successNotification = true;

        setTimeout(() => {
          this.successNotification = false;
        }, 4000);        
      },
      showNotificationDialog(dataItem){
        this.notificationDialog = true;
        this.dialogData = dataItem;
      },
      sortChangeHandler(e) {
        this.sort = e.sort;
      },
      toggleConfirmDialog() {
        this.showConfirmDialog = !this.showConfirmDialog;

        if (!this.showConfirmDialog) {
          this.confirmMsg = '';
          this.confirmNote = '';
          this.confirmFunction = null;
        }
      },
      toggleErrorDialog() {
        this.showErrorDialog = !this.showErrorDialog;

        if (!this.showErrorDialog) {
          this.errorMsg = '';
          this.errorTitle = '';
        }
      },
    },
    async mounted() {
      this.token = await auth.acquireTokenResponse();
      window.addEventListener("resize", this.getScreenWidth);
      //this.azureId = '11127588-9290-464e-8d06-3e14fec2b0ae'
      this.azureId = localStorage.getItem('azure_id');
      this.userRoles = localStorage.getItem('user_roles');
      this.checkGrouping(this.azureId);
      //console.log(this.azureId);
      this.fetchData('openTaskData', 'openTaskLoaded', apiService.GetSwiftDashboardData, this.azureId)
      .then(() => this.processOpenTasks());
      this.fetchData('newAppsData', 'newAppsLoaded', apiService.GetSwiftDashboardNewApplications, this.azureId)
        .then(() => {
          this.newAppsData.forEach((t) => {
            t.logmsdatestarted = t.logmsdatestarted ? new Date(t.logmsdatestarted) : null;
          })
        this.newAppsTotal = this.newAppsData.length;
        //For some reason this won't display if it's 0 when the page renders so it's being defaulted to 1 then decrementing here so it can display accurately
        this.pipelineManagementSum--;
        this.pipelineManagementSum += this.newAppsTotal;
      });
      this.fetchData('dailyFeedSentData', 'dailyFeedLoaded', apiService.GetDailyFeed, this.azureId)
      .then(() => this.processDailyFeed());
      this.fetchData('expiringLocksData', 'expiringLocksLoaded', apiService.GetExpiringLocks, this.azureId)
        .then(() => {
          this.expiringLocksData.forEach((t) => {
            t.f2149 = t.f2149 ? new Date(t.f2149) : null;
            t.f2151 = t.f2151 ? new Date(t.f2151) : null;
            t.cust17fv = t.cust17fv ? new Date(t.cust17fv) : null;

          })
        this.expiringLocksTotal = this.expiringLocksData.length;
        this.pipelineManagementSum += this.expiringLocksTotal;
      });
      this.fetchData('disclosuresData', 'disclosuresLoaded', apiService.GetLODisclosuresCount, this.azureId)
      .then(() => {
        this.disclosuresTotal = this.disclosuresData.length;
        this.pipelineManagementSum += this.disclosuresTotal;
      });
      this.fetchData('unlockedLoansData', 'unlockedLoansLoaded', apiService.GetUnlockedLoans, this.azureId)
        .then(() => {
          this.unlockedLoansData.forEach((t) => {
            t.cust17fv = t.cust17fv ? new Date(t.cust17fv) : null;
          })
        this.unlockedLoansTotal = this.unlockedLoansData.length;
        this.pipelineManagementSum += this.unlockedLoansTotal;
      });
      if (this.taskSwitchChecked) {        
        this.fetchData('internalTasksData', 'internalTasksLoaded', apiService.GetGroupingInternalTasks, this.azureId)
          .then(() => this.processInternalTasks());
      } else {
        this.fetchData('internalTasksData', 'internalTasksLoaded', apiService.GetInternalTasks, this.azureId)
          .then(() => this.processInternalTasks());
      }
      //this.fetchData('disclosuresData', 'disclosuresLoaded', apiService.GetLODisclosuresCount, '13e4e176-96d5-412f-8038-403975628113');
    },
    async created() {
      const taskSwitch = this.getCookie('taskSwitch');
      this.taskSwitchChecked = taskSwitch == 'on' ? true : false;
      const msalAppInstance = await auth.init();

      msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {

        if (!tokenRes) {
          if (!auth.user()) {
            auth.login();
          }
        }
      });
    },
  }
</script>

<style>



  root {
    font-size: 14px;
  }
  .consumer-dialog.confirm-dialog .confirm-msg {
    font-size: 2.4rem;
  }

  .consumer-dialog.confirm-dialog .confirm-note {
    font-weight: 600;
    margin-top: 25px;
    font-size: 2.2rem;
  }

  .consumer-dialog.confirm-dialog .k-window.k-dialog {
    min-width: 40%;
    min-height: 28%;
    text-align: center;
  }

  .consumer-dialog.confirm-dialog .dialog__btn.dialog__btn--save {
    background-color: var(--con-primary-ltr);
    font-weight: 600;
  }
  .consumer-dialog.confirm-dialog .confirm-msg {
    font-size: 2rem;
  }

  .consumer-dialog.confirm-dialog .confirm-note {
    font-weight: 600;
    margin-top: 25px;
    font-size: 1.8rem;
  }

  .consumer-dialog.confirm-dialog .k-window.k-dialog {
    max-width: 95%;
    min-height: 28%;
    text-align: center;
  }

  .consumer-dialog.confirm-dialog .k-window.k-dialog .k-dialog-title {
      font-size: 2.2rem;
    }

  .consumer-dialog.confirm-dialog .dialog__btn.dialog__btn--save,
  .consumer-dialog.confirm-dialog .dialog__btn.dialog__btn--close {
    font-size: 1.9rem;
  }

  .consumer-dialog.confirm-dialog .dialog__btn.dialog__btn--save {
    background-color: var(--con-primary-ltr);
    font-weight: 600;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .card-container-flex{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
      justify-content: center;
      align-items: center;

  }
  .card-container-flex .dashboard-container{
      min-width: 160px;
      height: 140px;
      width: 50%;
  }
    .card-container-flex .k-chart{
        height: unset;
    }
    .card-container-horizontal {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
/*  .card-container-horizontal > .dashboard-container{
      width: 130px;
  }*/
  .card-container-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 35%;
    /*height: 100%;*/
    /*margin: 0 15px 15px 15px;*/
  }
  .column-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    /*padding: 10px 0;*/
    color: rgb(128, 128, 128, 0.6);
    border-bottom: 1px solid rgba(128, 128, 128, 0.6);
  }
  .column-header-container{
    display: flex;
    flex-direction: row;
    justify-content: start;

  }
    .column-header-container .k-switch-md{
        font-size: 9px;
    }
    .command-center-container {
      height: 100%;
      width: 100%;
      background: #f7f7f7;
      overflow: scroll;
      padding: 5px;
      display: flex;
      flex-direction: column-reverse;
    }
      .command-center-container .toolbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .command-center-container .search-input {
        width: 15vh;
        margin-right: 10px;
      }

      .command-center-container .k-toolbar .k-input {
        width: unset;
      }

      .command-center-container .exportExcelButton {
        flex-shrink: 0;
      }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-size: 2rem;
  }
  .dialog-container{
    height: 300px;
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .dialog-grid-container {
    height: 600px;
    width: 80vw;
    overflow: hidden;
  }
  .donut-center-text {
    font-size: 3rem;
  }
  .header-icon{
      font-size: 3rem;
  }
  .header-icon i {
    --fa-primary-color: #051d3a;
  }
  .header-text {
    display: flex;
    font-size: 2rem;
    align-items: center;
    padding-left: 8px;
  }
  .left-column {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .notification-container{
      display: flex;
      justify-content: center;

  }
  .pipeline-cell {
    white-space: normal;
    cursor: pointer;
  }
  .right-column{
    display: flex;
    flex-direction: column;
    align-items: end;
  }
  .single-card-container{
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
  .task-title{
      height: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.3rem;
  }
  .task-total{
      font-size: 2rem;
      font-weight: 600;
  }
  .task-total-container{
      cursor: pointer;
      height: 100%;
      display: flex;
      /*flex: 1;*/
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      width: 100%;
  }
  .task-total-divider{
      width: 5px;
      background: black;
      height: 50px;
      border-radius: 2px;
      margin: 3px;
  }
  .task-totals-section {
    margin: 0 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .totals-container {
    /*flex: 1;*/
    width: 100%;
    /*padding-bottom: 15px;*/
    display: flex;
    flex-direction: column;
    justify-content: start !important;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background: white;
    margin: 3px;
    height: 100%;
  }
  .card-container-horizontal > dashboard-container{
    flex: 1;
  }

  .two-pane{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .two-pane-horizontal{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .two-pane-vertical {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  /*Annoying Kendo styling*/
  .k-dialog-wrapper .k-dialog {
    border-radius: 16px;
    max-height: 80%;
  }

  .notification-container .k-dialog {
    max-height: 98%;
    width: 80%
  }

  .notification-container .k-dialog .html-cont {
    height: 100%;
    overflow: auto; /* Still allow scrolling if necessary */
    /* Hide scrollbar for Chrome, Safari, and Opera */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

    .notification-container .k-dialog .html-cont::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and Opera */
    }

  .notification-container > div.k-dialog-wrapper > div.k-window.k-dialog {
    max-width: 800px;
  }

  .k-window-titlebar {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .conditions__notification-container .k-notification {
    padding: 0 5px;
  }

  .conditions__notification-container .k-notification-content {
    font-size: .9rem;
  }

  @media (max-width: 925px) {
    .command-center-container{
          flex-direction: column-reverse;
      }
      .card-container{
          flex-direction: row;
          width: 100%;
      }
      .single-card-container{
          width: 100%;
      }
    .card-container-flex .dashboard-container {
      min-width: 0; 
      height: 120px;
      width: 50%;
    }
  }
  @media (max-width: 800px) {
    .two-pane{
      flex-direction: column;
    }

  }
  @media (max-width: 640px){
      .card-container{
          flex-direction: column;
      }
  }
  @media (max-width: 480px){
    .donut-center-text{
        font-size: 2rem;
    }
    .pipeline-cell{
      font-size: .7rem;
    }
    .header-icon{
        font-size: 2rem;
    }
    .header-text{
        font-size: 1.2rem;
    }
    .task-title{
        font-size: .8rem;
    }
    .task-total{
        font-size: 1.5rem;
    }
/*    .column-header{
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
    }*/


  }
  @media (max-width: 375px){
      .task-title{
          font-size: .65rem;
      }
  }
</style>
