
import axios from 'axios';
var API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
//var API_ENDPOINT = "https://localhost:44346/";

export default {
  FetchData(LPID, token) {
    return axios.get(API_ENDPOINT + `api/Contacts/GetContactsByLPID?LPID=${LPID}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    );
  },
  async GetExt1(azureId, token) {
    return axios.get(API_ENDPOINT + `api/Contacts/GetExtensionAttribute1ByUserId?Id=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    );
  },
  async GetExt4(azureId, token) {
    return axios.get(API_ENDPOINT + `api/Contacts/GetExtensionAttribute4ByUserId?Id=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    );
  },
  async GetRoles(azureId, token) {
    return axios.get(API_ENDPOINT + `api/Roles/GetUserRoles?Id=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    );
  },
  async GetAll(azureId, token) {
    return axios.get(API_ENDPOINT + `api/Contacts/GetAllActiveOrOriginated?Id=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    );
  },
  async GetAllByORGID(ORGID, token) {
    return axios.get(API_ENDPOINT + `api/Contacts/GetAllActiveOrOriginatedByORGID?Id=${ORGID}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    );
  },
  async GetSwiftAppPipelineNonActive(azureId, token) {
    return axios.get(API_ENDPOINT + `api/Contacts/GetSwiftAppPipelineNonActive?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetSwiftAppPipeline(azureId, token) {
    return axios.get(API_ENDPOINT + `api/Contacts/GetSwiftAppPipeline?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetSwiftDashboardData(azureId, token) {
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetSwiftDashboardData?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetSwiftDashboardNewApplications(azureId, token) {
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetSwiftDashboardNewApplications?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetDailyFeed(azureId, token) {
    //console.log("api service azureId = " + azureId);
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetDailyFeed?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetExpiringLocks(azureId, token) {
    //console.log("api service azureId = " + azureId);
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetExpiringLocks?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetInternalTasks(azureId, token) {
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetInternalTasks?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetInternalTasksByOrgId(azureId, token) {
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetBranchInternalTasks?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetTasksGrouped(token) {
    return axios.get(API_ENDPOINT + `api/CGSTask/GetTasksCountGrouped`,
      {
        headers: {
          "Authorization": "Bearer" + token
        }
      }
    );
  },
  async GetUsersByOrgIdByRole(azureId, token) {
    return axios.get(API_ENDPOINT + `api/ContactTeams/GetUsersByOrgIdByRole?azure_id=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    );
  },
  async GetUsers(token) {
    return axios.get(API_ENDPOINT + 'api/ContactTeams/GetUsers', 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  },
  async GetNotificationTemplates(token) {
    return axios.get(API_ENDPOINT + 'api/EmailManager/GetNotificationTemplates', 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  },
  async UpsertNotificationCCSettings(request, token, update = 'false') {
    return axios.post(API_ENDPOINT + `api/NotificationCCSettings/UpsertNotificationCCSettings?update=${update}`, request, 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  },
  async GetUsersNotificationCCSettings(email, type, token) {
    return axios.get(API_ENDPOINT + `api/NotificationCCSettings/GetUsersNotificationCCSettings?userEmail=${email}&type=${type}`, 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  },
  async DeleteNotificationCCSettings(request, token) {
    return axios.post(API_ENDPOINT + 'api/NotificationCCSettings/DeleteNotificationCCSettings', request, 
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    )
  },
  async GetLODisclosuresCount(azureId, token) {
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetLODisclosuresCount?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    );

  },
  async GetUnlockedLoans(azureId, token) {
    //console.log("api service azureId = " + azureId);
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetUnlockedLoans?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },
  async GetPostCloseSummarys(token) {
    //console.log("api service azureId = " + azureId);
    //return await axios.get('https://localhost:44346/' + `api/SwiftAppAI/GetPostCloseSummarys`,
    return await axios.get(API_ENDPOINT + `api/SwiftAppAI/GetPostCloseSummarys`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      }
    );
  },

  async CreateManualBorrowerTaskNotifications(loanId, token) {
    console.log(token);
    return axios.post(API_ENDPOINT + `api/LoanLaunchNotifications/CreateManualBorrowerTaskNotificationsDashboard?loanId=${loanId}`, {},
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async GetUsersNotificationSubscriptionSettings(azureId, token) {
    return axios.get(API_ENDPOINT + `api/EmailMessaging/GetUsersNotificationSubscriptionSettings?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      });
  },
  async DeleteNotificationOptOuts(request, token) {
    return axios.post(API_ENDPOINT + `api/NotificationOptOut/NotificationOptOutsDelete`, request,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async UpsertNotificationOptOuts(request, token) {
    return axios.post(API_ENDPOINT + `api/NotificationOptOut/NotificationOptOutsUpsert`, request,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async GetLoanDisplayData(loanNumber, token) {
    return axios.get(API_ENDPOINT + `api/Loans/GetLoanDisplayData?loanNumber=${loanNumber}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      });
  },
  async GetUsersSMSPushSubscriptionSettings(azureId, token) {
    return axios.get(API_ENDPOINT + `api/NotificationOptOut/GetUsersSMSPushSubscriptionSettings?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      });
  },
  async DeletePushOptIns(request, token) {
    return axios.post(API_ENDPOINT + `api/NotificationOptOut/PushOptInsDelete`, request,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async UpsertPushOptIns(request, token) {
    return axios.post(API_ENDPOINT + `api/NotificationOptOut/PushOptInsUpsert`, request,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async DeleteSMSOptIns(request, token) {
    return axios.post(API_ENDPOINT + `api/NotificationOptOut/SMSOptInsDelete`, request,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async UpsertSMSOptIns(request, token) {
    return axios.post(API_ENDPOINT + `api/NotificationOptOut/SMSOptInsUpsert`, request,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async GetGrouping(azureId, token) {
    return axios.get(API_ENDPOINT + `api/UserSettings/GetGrouping?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async GetGroupingInternalTasks(azureId, token) {
    return axios.get(API_ENDPOINT + `api/SwiftAppAI/GetGroupingInternalTasks?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async GetUsersExternalSubscriptionSettings(azureId, token) {
    return axios.get(API_ENDPOINT + `api/EmailMessaging/GetUsersExternalSubscriptionSettings?azureId=${azureId}`,
      {
        headers: {
          "Authorization": "Bearer " + token
        }

      });
  },
  async DeleteExternalNotificationOptOuts(request, token) {
    return axios.post(API_ENDPOINT + `api/NotificationOptOut/ExternalNotificationOptOutsDelete`, request,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
  async UpsertExternalNotificationOptOuts(request, token) {
    return axios.post(API_ENDPOINT + `api/NotificationOptOut/ExternalNotificationOptOutsUpsert`, request,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      });
  },
};
