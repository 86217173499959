<template>
    <div class="edit-notification-cc">
        <h2 class="tab-title">Select A User to View Their CC Settings</h2>
        <div class="actions-container">
            <k-dropdown class="select-user-dropdown"
                        :data-items="filteredAzureUsers"
                        :text-field="'name'"
                        :data-item-key="'id'"
                        v-model="selectedAzureUser"
                        :filterable="true"
                        @filterchange="onUserFilterChange"
                        @change="onUserSelected"></k-dropdown>
        </div>
        <div class="pipeline-container">
            <k-grid :data-items="filteredUsersCCSettings"
                    :data-item-key="'guid'"
                    :columns="pipelineColumns"
                    :column-menu="true"
                    :sortable="true"
                    :pageable="gridPageable"
                    :filterable="true" 
                    :sort="sort"
                    :filter="filter"
                    :take="take"
                    :total="total"
                    :skip="skip"
                    :selectable="true"
                    :selected-field="selectedField"
                    @datastatechange="dataStateChange"
                    @selectionchange="onSettingSelectionChange"
                    @headerselectionchange="onHeaderSelectionChange"
                    @filterchange="onFilterChange"
                    @sortchange="onSortChange">
                    <template v-slot:pipelineActions="{ props }">
                        <div class="pipeline-actions-container">
                            <k-button class="delete-item__btn" @click.stop="onDeleteSetting(props.dataItem)"><i class="fa-duotone fa-trash" title="Delete Setting"></i></k-button>
                            <k-button class="delete-item__btn" @click.stop="onEditSetting(props.dataItem)"><i class="fa-duotone fa-pen-to-square" title="Edit Setting"></i></k-button>
                        </div>
                    </template>
                    <k-grid-toolbar>
                        <h2 class="pipeline-title"><i class="fa-light fa-envelopes"></i> CC Settings</h2>

                        <span class="k-textbox k-grid-search k-display-flex">
                            <k-input :style="{ width: '300px' }"
                                    placeholder="Search in all columns..."
                                    :value="searchWord"
                                    :icon-name="'search'"
                                    @input="onSearchChange"></k-input>
                        </span>
                        <k-button class="delete-selected-btn" @click="onDeleteSelectedSettings" :disabled="deleteSelectedBtnDisabled">Delete Selected</k-button>
                    </k-grid-toolbar>
            </k-grid>
        </div>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deleteSettings"></confirm-dialog>

        <edit-setting-dialog :showDialog="showEditSettingDialog"
                             :setting="selectedCCSetting"
                             :notifications="notifications"
                             @dialogclosed="toggleEditSettingDialog"
                             @settingupdated="onSettingUpdated"></edit-setting-dialog>

        <div class="component__loader-container" v-if="loading">
            <k-loader size="large" type="infinite-spinner"></k-loader>
        </div>

        <k-notification-group class="default__notification-container">
            <k-fade :appear="successNotification">
                <k-notification v-if="successNotification"
                                :type="{ style: 'success', icon: true }"
                                :closable="true"
                                @close="onCloseNotification('successNotification')">
                    <span>{{ notificationMsg }}</span>
                </k-notification>
            </k-fade>
        </k-notification-group>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Loader } from "@progress/kendo-vue-indicators";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
    import { Input } from '@progress/kendo-vue-inputs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Dialog } from '@progress/kendo-vue-dialogs';

    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import confirmDialog from '../components/confirmDialog.vue';
    import editSettingDialog from '../components/editSettingDialog.vue';

    import apiService from '../api/api.service.js';
    import auth from '../authConfig.js';

    import cloneDeep from 'lodash.clonedeep';

    export default {
        name: 'EditNotificationCC',
        props: ['azureUsers', 'notifications'], 
        data() {
            return {
                azureId: null,
                confirmMsg: '',
                deleteItems: [],
                errorMsg: '',
                errorTitle: '',
                filter: null,
                filteredAzureUsers: [],
                filteredUsersCCSettings: [],
                gridPageable: {
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                },
                loading: true,
                notificationMsg: '',
                searchWord: '',
                selectedAzureUser: { name: 'Select User', email: null, id: Math.random().toString() },
                selectedCCType: 'To',
                selectedField: 'selected',
                selectedCCSetting: null,
                showConfirmDialog: false,
                showEditSettingDialog: false,
                showErrorDialog: false,
                skip: 0,
                sort: [],
                successNotification: false,
                take: 50,
                total: 0,
                unfilteredAzureUsers: [],
                usersCCSettings: []
            }
        },
        computed: {
            areAllSettingsSelected() {
                return this.selectedCCRows.length === this.filteredUsersCCSettings.length && this.selectedCCRows.length > 0;
            },
            deleteSelectedBtnDisabled() {
                return !this.usersCCSettings.some(setting => setting.selected);
            },
            pipelineColumns() {
                return [
                    { field: 'selected', width: '45px', columnMenu: false, filterable: false, headerSelectionValue: this.areAllSettingsSelected},
                    { field: 'email_address', title: 'Notifications To' },
                    { field: 'templateName', title: 'Notification Title' },
                    { field: 'templateSubject', title: 'Notification Subject' },
                    { field: 'displayName', title: 'CC User Name' },
                    { field: 'cc_address', title: 'CC User Email' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ]
            },
            selectedCCRows() {
                return this.usersCCSettings.filter(setting => setting.selected === true);
            }
        },
        async mounted() {
            const msalAppInstance = await auth.init();

            msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {
                if (!tokenRes) {
                    if (!auth.user()) {
                        auth.login();
                    }
                }

                this.azureId = localStorage.getItem('azure_id');

                if (!this.azureId) return;

                if (this.azureUsers.length > 0) {
                    this.unfilteredAzureUsers = cloneDeep(this.azureUsers);
                    this.filteredAzureUsers = cloneDeep(this.azureUsers);
                } else {
                    await this.getUsersByOrgId();
                }

                if (this.filteredAzureUsers.length === 1) {
                    this.selectedAzureUser = this.filteredAzureUsers[0];
                    this.getUsersNotificationCCSettings(this.selectedAzureUser.email);
                }
                this.loading = false;
            });
        },
        methods: {
            assignCCSettings(settingsData) {
                settingsData.forEach(setting => {
                    setting.selected = false;

                    if (!setting.template_id) {
                        setting.templateName = 'All Notifications';
                    } else {
                        let notification = this.notifications.find(noti => noti.id === setting.template_id);

                        if (notification) {
                            setting.templateName = notification.displayName;
                            setting.templateSubject = notification.subject;
                        }
                    }
                });

                this.usersCCSettings = settingsData;
                this.filteredUsersCCSettings = cloneDeep(this.usersCCSettings);

                this.getData();
            },
            createAppState(dataState) {
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            async deleteSettings() {
                this.toggleConfirmDialog();

                this.loading = true;

                let request = {
                    userEmail: this.selectedAzureUser.email,
                    type: 'To',
                    settingIds: this.deleteItems.map(item => item.guid)
                }

                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteNotificationCCSettings(request, tokenResponse.accessToken)
                    .then(res => {
                        this.loading = false;

                        if (res?.data?.data) {
                            this.assignCCSettings(res.data.data);
                            this.showNotification('Setting deleted successfully');
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Delete Setting';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });

            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredUsersCCSettings = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredUsersCCSettings = this.usersCCSettings.filter(item => {
                        return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
                    });
                } else {
                    this.filteredUsersCCSettings = cloneDeep(this.usersCCSettings);
                }

                if (this.sort.length > 0) {
                    this.filteredUsersCCSettings = orderBy(this.usersCCSettings, this.sort);
                }

                if (this.filter) {
                    this.filteredUsersCCSettings = filterBy(this.filteredUsersCCSettings, this.filter);
                } 

                return this.filteredUsersCCSettings;
            },
            async getUsersByOrgId() {
                const tokenResponse = await auth.acquireTokenResponse(); 

                //testing azure ids...
                //this.azureId = '37a013ac-f5f0-46c1-8734-5e855474a4d4' //Jared Peterson
                //this.azureId = '11127588-9290-464e-8d06-3e14fec2b0ae' //Marty Schaefer
                //this.azureId = 'd588e053-971a-4e87-8909-58dc9e36880b' //Doug Welch

                return apiService.GetUsersByOrgIdByRole(this.azureId, tokenResponse.accessToken)  
                    .then(res => {
                        if (res.data?.data) {
                            this.unfilteredAzureUsers = res.data.data.map(user => {
                                return {
                                    name: user.displayName,
                                    email: user.email,
                                    id: user.id
                                }
                            });

                            this.filteredAzureUsers = cloneDeep(this.unfilteredAzureUsers);
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch Users';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    });
            },
            async getUsersNotificationCCSettings(userEmail) {
                this.loading = true;

                const tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetUsersNotificationCCSettings(userEmail, this.selectedCCType, tokenResponse.accessToken)
                    .then(res => {
                        if (res.data?.data) {
                            this.assignCCSettings(res.data.data);
                            this.loading = false;
                        }
                    }).catch(error => {
                        let errorTitle = 'Failed to Fetch User CC Settings';

                        let errorMsg;

                        if(error.response?.data?.data) {
                            errorMsg = "Error: \n\n" + error.response.data.data;
                        } else {
                            errorMsg = '';
                        }

                        this.onRequestError(errorTitle, errorMsg);
                    })
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDeleteSelectedSettings() {
                this.deleteItems = this.selectedCCRows;
                this.confirmMsg = 'Are you sure you would like to delete these settings?';
                this.toggleConfirmDialog();
            },
            onDeleteSetting(setting) {
                this.deleteItems = [setting];
                this.confirmMsg = `Are you sure you would like to delete this setting?`;
                this.toggleConfirmDialog();
            },
            onEditSetting(setting) {
                this.selectedCCSetting = setting;
                this.toggleEditSettingDialog();
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            onHeaderSelectionChange(evt) {
                if (!evt?.event?.target) return;

                let checked = evt.event.target.checked;

                this.filteredUsersCCSettings.forEach(setting => {
                    let allSettingsMatch = this.usersCCSettings.find(s => s.guid === setting.guid);
                    
                    if (allSettingsMatch) {
                        allSettingsMatch[this.selectedField] = checked;
                    }
                });
                
                this.getData();
            },
            onRequestError(errorTitle, errorMsg) {
                this.loading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSettingSelectionChange(evt) {
                let setting = this.usersCCSettings.find(setting => setting.guid === evt.dataItem.guid);

                if (setting) {
                    setting[this.selectedField] = !setting[this.selectedField];
                    this.getData();
                }
            },
            onSettingUpdated() {
                this.toggleEditSettingDialog();
                this.getUsersNotificationCCSettings(this.selectedAzureUser.email);
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            onUserFilterChange(evt) {
                this.filteredAzureUsers = filterBy(cloneDeep(this.unfilteredAzureUsers), evt.filter);
            },
            onUserSelected(evt) {
                if (this.selectedAzureUser.email) {
                    this.getUsersNotificationCCSettings(this.selectedAzureUser.email);
                }
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleEditSettingDialog() {
                this.showEditSettingDialog = !this.showEditSettingDialog;

                if (!this.showEditSettingDialog) {
                    this.selectedCCSetting = null;
                }
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                }
            }
        },
        components: {
            'k-loader': Loader,
            'k-dropdown': DropDownList,
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'k-input': Input,
            'k-button': Button,
            'confirm-dialog': confirmDialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-dialog': Dialog,
            'edit-setting-dialog': editSettingDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/editNotificationCC.css';
</style>