<template>
  <div class="post-close-connect">
    <div v-if="loading" class="post-close-connect__loader-container">
      <k-loader size="large" type="infinite-spinner"></k-loader>
    </div>

    <!--Error Dialog-->
    <error-dialog v-if="showErrorDialog" :errorMsg="errorMsg" :errorTitle="errorTitle" @close="onCloseErrorDialog"/>

    <!--Summary Dialog-->
    <k-dialog v-if="isSummaryDialogVisible" :title="summaryDialogTitle" class="default__dialog post-close-summary-dialog" @close="onCloseSummaryDialog">
      <k-grid class="post-close-summary-grid"
              :data-items="summaryGridItems"
              :columns="summaryCardColumns"
              :column-menu="true"
              :filter="summaryFilter"
              :filterable="true"
              :pageable="summaryPageable"
              :skip="summarySkip"
              :sort="summarySort"
              :sortable="true"
              :take="summaryTake"
              :total="summaryTotal"
              @columnreorder="columnReorder"
              @datastatechange="summaryDataStateChange"
              @filterchange="summaryFilterChange"
              @pagechange="summaryPageChangeHandler"
              @sortchange="summarySortChangeHandler">

        <k-grid-toolbar>
          <span class="k-grid-input-search">
            <k-input :style="{ width: '300px' }"
                     placeholder="Search in all columns..."
                     :value="summarySearchWord"
                     :icon-name="'search'"
                     @input="summaryOnSearchChange"></k-input>
          </span>
        </k-grid-toolbar>
      </k-grid>
    </k-dialog>

    <div v-if="!loading" class="post-close-connect-cont">
      <div class="post-close-summary-cards-cont">
        <summary-card :postCloseSummaryData="postCloseSummaryData" :summaryCardTitles="summaryCardTitles" @open-summary-dialog="onOpenSummaryDialog" />
      </div>
      <k-grid class="post-close-connect-grid"
              :data-items="gridItems"
              :columns="columns"
              :column-menu="true"
              :expand-field="'expanded'"
              :filter="filter"
              :filterable="true"
              :group="group"
              :groupable="true"
              :pageable="pageable"
              :skip="skip"
              :sort="sort"
              :sortable="true"
              :take="take"
              :total="total"
              @columnreorder="columnReorder"
              @datastatechange="dataStateChange"
              @expandchange="expandChange"
              @filterchange="filterChange"
              @groupchange="groupChangeHandler"
              @pagechange="pageChangeHandler"
              @sortchange="sortChangeHandler"
              @rowclick="onRowClick">

        <k-grid-toolbar>
          <span class="k-grid-input-search">
            <k-input :style="{ width: '300px' }"
                     placeholder="Search in all columns..."
                     :value="searchWord"
                     :icon-name="'search'"
                     @input="onSearchChange"></k-input>
          </span>
        </k-grid-toolbar>
      </k-grid>


    </div>
  </div>
</template>

<script>
  import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
  import { DropDownList } from '@progress/kendo-vue-dropdowns';
  import { Input } from '@progress/kendo-vue-inputs';
  import { Card } from "@progress/kendo-vue-layout";
  import { Loader } from "@progress/kendo-vue-indicators";
  import { orderBy, filterBy, process } from '@progress/kendo-data-query';
  import { Dialog } from '@progress/kendo-vue-dialogs';
  import { Button } from '@progress/kendo-vue-buttons';

  import cloneDeep from 'lodash.clonedeep';
  import apiService from '../api/api.service.js';
  import auth from '../authConfig.js';
  import { debounce } from 'lodash';

  //Components
  import SummaryCard from './summaryCard.vue';
  import ErrorDialog from './errorDialog.vue';

  export default {
    components: {
      // Kendo
      'k-grid': Grid,
      'k-dropdown-list': DropDownList,
      'k-input': Input,
      'k-grid-toolbar': GridToolbar,
      'k-loader': Loader,
      'k-card': Card,
      'k-dialog': Dialog,
      'k-button': Button,

      // Components
      'summary-card': SummaryCard,
      'error-dialog': ErrorDialog,
    },
    data() {
      return {
        columns: [
          { field: 'ms_fun_date', title: 'Funded Date', format: "{0:MM/dd/yyyy}", type: 'date', filter: 'date' },
          { field: 'ms_fun_string', title: 'Funded Date String', hidden: true },
          { field: 'f364', title: 'Loan #' },
          //{ field: 'cxecloseindicator', title: 'eClosed' },
          { field: 'borrowerfullname', title: 'Borrower' },
          { field: 'f2', title: 'Loan Amount', format: "{0:c2}", filter: 'numeric' },
          { field: 'f2_string', title: 'Loan Amount String', hidden: true },
          { field: 'losinvestor', title: 'Investor' },
          { field: 'cust19fv', title: 'Investor #' },
          { field: 'postcloser', title: 'Post Close' },
          { field: 'postclosestatus', title: 'Status' },
          { field: 'orgid', title: 'Org' },
        ],
        errorMsg: '',
        errorTitle: '',
        filter: null,
        filteredItems: null,
        firstRender: true,
        gridItems: [],
        group: [],
        isPostCloserOptionsVisible: false,
        isSummaryDialogVisible: false,
        loading: true,
        pageable: {
          buttonCount: 10,
          info: true,
          type: 'numeric',
          pageSizes: [50, 100, 150, 200],
          previousNext: true,
        },
        postCloseSummaryData: null,
        postCloseGridData: [],
        postCloserOptionsData: [],
        searchWord: "",
        showErrorDialog: false,
        skip: 0,
        sort: [],
        summaryCardColumns: [
          { field: 'name', title: 'Name' },
          { field: 'units', title: 'Units', filter: 'numeric' },
          { field: 'volume', title: 'Volume', format: "{0:c2}", filter: 'numeric' },
          { field: 'volume_string', title: 'Volume String', hidden: true },
        ],
        summaryCardGridItems: [],
        summaryCardTitles: [
          { title: "Units", value: 0 },
          { title: "Volume", value: 0 },
          { title: "Aged Loans > 20 days", value: 0 },
          { title: "Investor Summary", value: [] },
          { title: "Post Closer Summary", value: [] },
          { title: "Date of Oldest Request", value: 0 },
          { title: "Total Requests", value: 0 },
          { title: "Request > 20 days", value: 0 }
        ],
        summaryDialogTitle: "",
        summaryFilter: null,
        summaryFilteredItems: null,
        summaryGridItems: [],
        summaryPageable: {
          buttonCount: 10,
          info: true,
          type: 'numeric',
          pageSizes: [50, 100, 150, 200],
          previousNext: true,
        },
        summarySearchFilter: null,
        summarySearchWord: "",
        summarySkip: 0,
        summarySort: [],
        summaryTake: 50,
        summaryTotal: 0,
        take: 50,
        total: 0,
        userRoles: [],
      };
    },
    computed: {

    },
    async mounted() {
      // Add resize event listener, debounce to reduce rapid firing
      window.addEventListener("resize", debounce(this.getScreenWidth, 100));

      // Get user roles from localStorage once
      const userRolesFromLocalStorage = localStorage.getItem('user_roles');
      this.userRoles = userRolesFromLocalStorage ? userRolesFromLocalStorage.split(',') : [];

      // Fetch data if userRoles does not include 'Corp'
      if (this.userRoles.includes("Post Closer") || this.userRoles.includes("Corp")) {
        this.fetchData();
        this.firstRender = false;
      } else {
        loading = false;
      }

      // Set interval to periodically fetch data every 5 minutes (300000 ms)
      this.fetchInterval = setInterval(() => {
        if (this.userRoles.includes("Post Closer") || this.userRoles.includes("Corp")) {
          this.fetchData();
        } else {
          loading = false;
        }
      }, 300000);
    },

    beforeDestroy() {
      // Clean up event listener and interval
      window.removeEventListener("resize", this.getScreenWidth);
      clearInterval(this.fetchInterval);
    },
    methods: {
      columnReorder(options) {
        this.columns = options.columns;
      },
      dataStateChange(e) {
        this.skip = e.skip;
        this.take = e.take;
        this.processData();
      },
      expandChange: function (event) {
        event.dataItem[event.target.$props.expandField] = event.value;
      },
      async fetchData() {
        //debugger;
        this.postCloseGidUnits = 0;
        this.postCloseGidVolume = "$0.00";

        if (this.firstRender) {
          this.loading = true;
        }
        try {
          const tokenResponse = await auth.acquireTokenResponse(); // Get Auth Token

          this.azureId = localStorage.getItem('azure_id');
          if (!tokenResponse) throw new Error("Failed to acquire token response");
          //debugger;
          const response = await apiService.GetPostCloseSummarys(tokenResponse.accessToken);

          const data = response.data;
          if (data && data.issuccess) {
            // Format the data before applying filters and pagination
            this.postCloseGridData = data.data.postclosedatalist.map(item => {
              // Convert fundingdate to a Date object
              if (item.ms_fun) {
                item.ms_fun_string = this.formatDateString(item.ms_fun);
                item.ms_fun_date = new Date(item.ms_fun);
              }

              item.borrowerfullname = item.f4000 + " " + item.f4002;

              // Format f2 as a number with 2 decimal places
              if (item.f2 && item.f2 != "" && item.f2 != null) {
                item.f2 = parseFloat(item.f2);
                item.f2_string = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.f2);
              }
              else {
                item.f2 = 0.00;
                item.f2_string = '$0.00';
              }

              item.postclosestatus = "Pending Upload";

              if (item.cxpcseandoclear && (item.cxpcseandoclear == "X" || item.cxpcseandoclear == "x")) {
                item.postclosestatus = "E&O's Cleared";
                return item
              }

              if (item.cxpcseandoreceive && (item.cxpcseandoreceive == "X" || item.cxpcseandoreceive == "x")) {
                item.postclosestatus = "E&O's Received";
                return item
              }

              if (item.cxpcspackageupload && (item.cxpcspackageupload == "X" || item.cxpcspackageupload == "x")) {
                item.postclosestatus = "Package Uploaded";
                return item
              }

              return item;
            });

            // Update the summaryCardTitles
            this.summaryCardTitles = [
              {
                title: "Units",
                value: data.data.postclosesummary.units ? data.data.postclosesummary.units : "0"// Set value from API response
              },
              {
                title: "Volume",
                value: data.data.postclosesummary.volume ? data.data.postclosesummary.volume : "0" // Or whatever value you want to set
              },
              {
                title: "Aged Loans > 20 days",
                value: data.data.postclosesummary.agedloansover20days ? data.data.postclosesummary.agedloansover20days : "0"
              },
              {
                title: "Investor Summary",
                value: data.data.postclosesummary.investorsummary ? data.data.postclosesummary.investorsummary : []
              },
              {
                title: "Post Closer Summary",
                value: data.data.postclosesummary.postclosesummary ? data.data.postclosesummary.postclosesummary : []
              },
              {
                title: "Date of Oldest Request",
                value: data.data.postclosesummary.dateofoldestrequest ?
                  new Date(data.data.postclosesummary.dateofoldestrequest).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric"
                })
                  : "N/A"
              },
              {
                title: "Total Requests",
                value: data.data.postclosesummary.totalrequests ? data.data.postclosesummary.totalrequests : "0"
              },
              {
                title: "Request > 20 days",
                value: data.data.postclosesummary.requestover20days ? data.data.postclosesummary.requestover20days : "0"
              }
            ];



            this.processData();
          } else {
            console.error("No valid data received from API");
            this.onRequestError('Post Close Error', "No valid data received from API") 

            this.postCloseGridData = [];
          }
        }
        catch (error) {
          this.onRequestError('Post Close Error', error.message) 

        } finally {
          this.loading = false;
          this.processData();
        }
      },
      formatDateString(dateString) {
        if (dateString && dateString != "") {
          const date = new Date(dateString);
          const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
          const day = date.getDate().toString().padStart(2, '0');
          const year = date.getFullYear();

          dateString = `${month}/${day}/${year}`; // Formatted string
          return dateString;
        } else {
          return "";
        }
      },
      filterChange(e) {
        this.filter = e.filter;
        this.skip = 0;
        this.processData();
      },
      getFilteredItems() {
        if (this.searchWord) {
          this.filteredItems = this.postCloseGridData.filter(item => {
            return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
          });
        } else {
          this.filteredItems = cloneDeep(this.postCloseGridData);
        }

        if (this.sort.length > 0) {
          this.filteredItems = orderBy(this.filteredItems, this.sort);
        }

        if (this.filter) {
          this.filteredItems = filterBy(this.filteredItems, this.filter);
        }

        return this.filteredItems;
      },
      getScreenWidth() {
        this.windowWidth = window.innerWidth;
      },
      groupChangeHandler(e) {
        this.group = e.group;
        this.processData();
      },
      onCloseErrorDialog() {
        this.showErrorDialog = false;

        if (!this.showErrorDialog) {
          this.errorMsg = '';
          this.errorTitle = '';
        }
      },
      onCloseSummaryDialog(str) {
        this.isSummaryDialogVisible = !this.isSummaryDialogVisible
      },
      onOpenPostCloserOptionsDialog(dataItem) {
        //console.log('onOpenPostCloserOptionsDialog', dataItem);
        this.postCloserOptionsData = dataItem;
        this.isPostCloserOptionsVisible = true;

      },
      onOpenSummaryDialog(title) {
        this.isSummaryDialogVisible = !this.isSummaryDialogVisible;
        this.summaryResetGridView();

        this.summaryDialogTitle = title;

        // Find the selected summary using the provided title
        const selectedSummary = this.summaryCardTitles.find(item => item.title === title);

        // Check if the selected summary exists
        if (selectedSummary && selectedSummary.value) {
          // Map through the summary grid data to format it
          let updatedSummary = selectedSummary.value.map(item => {
            // Format volume as a number with 2 decimal places
            if (item.volume && item.volume !== "" && item.volume !== null) {
              item.volume_string = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.volume);
            } else {
              item.volume_string = '$0.00';
            }

            return item;
          });

          this.summaryCardGridItems = updatedSummary;
        } else {
          console.error(`No summary found for title: ${title}`);
          this.summaryCardGridItems = []; // Reset or handle error case as needed
        }

        this.summaryProcessData();
      },
      onRequestError(errorTitle, errorMsg) {
        this.isLoading = false;

        this.errorTitle = errorTitle;

        if (errorMsg) {
          this.errorMsg = errorMsg;
        } else {
          this.errorMsg = 'Please try again';
        }
        //console.log("onRequestError", this.showErrorDialog)
        this.showErrorDialog = true;
      },
      onRowClick(e) {
        //console.log('Current URL:', window.location.href);
        let environment = window.location.href;
        let baseUrl;

        if (environment.includes("localhost:")) {
          baseUrl = "http://localhost:8081/PostClose";
        } else if (environment.includes("https://dev.swiftapp.ai/")) {
          baseUrl = "https://dev-swift.goldstarfinancial.com/PostClose";
        } else {
          // Default to live environment
          baseUrl = "https://swift.goldstarfinancial.com/PostClose";
        }

        // Open the constructed URL in a new tab
        window.open(`${baseUrl}?id=${e.dataItem.id}`, '_blank');
      },
      onSearchChange(evt) {
        this.searchWord = evt.value;
        this.processData();
      },
      pageChangeHandler(e) {
        this.skip = e.page.skip;
        this.take = e.page.take;
        this.processData();
      },
      processData() {
        try {
          let combinedFilter = this.filter;

          // Combine the search filter with the existing filters
          if (this.searchFilter) {
            if (combinedFilter) {
              combinedFilter = {
                logic: 'and',
                filters: [combinedFilter, this.searchFilter]
              };
            } else {
              combinedFilter = this.searchFilter;
            }
          }
          let filteredData = this.getFilteredItems();


          // Apply the combined filter to the data
          const filteredItems = process(filteredData, {
            filter: combinedFilter
          }).data;

          // Update the total count to reflect the filtered data length
          this.total = filteredItems.length;

          // Apply pagination and sorting to the filtered data
          const paginatedData = process(filteredItems, {
            skip: this.skip,
            take: this.take,
            group: this.group,
            sort: this.sort,
          });

          this.gridItems = paginatedData.data || [];

        } catch (error) {

          this.gridItems = [];
          this.total = 0;
        }
      },
      sortChangeHandler(e) {
        this.sort = e.sort;
        this.processData();
      },
      summaryDataStateChange(e) {
        this.summarySkip = e.skip;
        this.summaryTake = e.take;
        this.summaryProcessData();
      },
      summaryFilterChange(e) {
        this.summaryFilter = e.filter;
        this.summarySkip = 0;
        this.summaryProcessData();
      },
      summaryGetFilteredItems() {
        if (this.summarySearchWord) {
          this.summaryFilteredItems = this.summaryCardGridItems.filter(item => {
            return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.summarySearchWord.toLowerCase()));
          });
        } else {
          this.summaryFilteredItems = cloneDeep(this.summaryCardGridItems);
        }

        if (this.sort.length > 0) {
          this.summaryFilteredItems = orderBy(this.summaryFilteredItems, this.summarySort);
        }

        if (this.filter) {
          this.summaryFilteredItems = filterBy(this.summaryFilteredItems, this.summaryFilter);
        }

        return this.summaryFilteredItems;
      },
      summaryOnSearchChange(evt) {
        this.summarySearchWord = evt.value;
        this.summaryProcessData();
      },
      summaryPageChangeHandler(e) {
        this.summarySkip = e.page.skip;
        this.summaryTake = e.page.take;
        this.summaryProcessData();
      },
      summaryProcessData() {
        try {
          let combinedFilter = this.summaryFilter;

          // Combine the search filter with the existing filters
          if (this.summarySearchFilter) {
            if (combinedFilter) {
              combinedFilter = {
                logic: 'and',
                filters: [combinedFilter, this.summarySearchFilter]
              };
            } else {
              combinedFilter = this.summarySearchFilter;
            }
          }
          let filteredData = this.summaryGetFilteredItems();


          // Apply the combined filter to the data
          const filteredItems = process(filteredData, {
            filter: combinedFilter
          }).data;

          // Update the total count to reflect the filtered data length
          this.summaryTotal = filteredItems.length;

          // Apply pagination and sorting to the filtered data
          const paginatedData = process(filteredItems, {
            skip: this.summarySkip,
            take: this.summaryTake,
            group: this.summaryGroup,
            sort: this.summarySort,
          });

          this.summaryGridItems = paginatedData.data || [];

        } catch (error) {

          this.summaryGridItems = [];
          this.summaryTotal = 0;
        }
      },
      summaryResetGridView() {
        this.summaryCardGridItems = [];
        this.summaryFilter = null;
        this.summaryFilteredItems = null;
        this.summaryGridItems = [];
        this.summarySearchFilter = null;
        this.summarySearchWord = "";
        this.summarySkip = 0;
        this.summarySort = [];
        this.summaryTake = 50;
        this.summaryTotal = 0;
      },
      summarySortChangeHandler(e) {
        this.summarySort = e.sort;
        this.summaryProcessData();
      },
    },

  };

</script>

<style>
  @import '/src/assets/css/postCloseConnect.css';
</style>
